// @flow
import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {SmsSendingsActionTypes} from './constants';
import {
    createSmsSending as createSmsSendingApi,
    getAboutSmsSending as getAboutSmsSendingApi,
    getSmsSendings as getSmsSendingsApi,
    sendSendingToArchive as sendSendingToArchiveApi,
    updateSmsSending as updateSmsSendingApi,
    updateStatus as updateStatusApi,
} from '../../helpers/';
import {setSending, smsApiResponseError, smsApiResponseSuccess} from './actions';
import {statuses} from '../../pages/smska/sms/constants';
import {fromTimestampStringToDateString} from '../../utils/moment';
import {addNotification} from "../notifications/actions";

function* getSendings(action) {
    try {
        const response = yield call(getSmsSendingsApi, action.payload);
        const { sendingList, totalCount } = response.data.sms;
        sendingList.forEach((sendingItem) => {
            sendingItem.status = statuses[sendingItem.status];
            sendingItem.date_start = fromTimestampStringToDateString(sendingItem.date_start);
            sendingItem.date_end = fromTimestampStringToDateString(sendingItem.date_end);
        });
        const pageCount = Math.ceil(totalCount / action.payload?.limit);
        yield put(smsApiResponseSuccess(SmsSendingsActionTypes.GET_SENDINGS, { sendingsList: sendingList, pageCount }));
    } catch (error) {
        yield put(smsApiResponseError(SmsSendingsActionTypes.GET_SENDINGS, error));
    }
}

function* getAboutSending(action) {
    try {
        const response = yield call(getAboutSmsSendingApi, action.payload);
        const sending = response.data.aboutSmsSending;
        yield put(setSending(sending))
    } catch (error) {
        yield put(smsApiResponseError(SmsSendingsActionTypes.GET_ABOUT_SENDING, error));
    }
}

function* sendSendingToArchive(action) {
    try {
        yield call(sendSendingToArchiveApi, action.payload);
        yield put(smsApiResponseSuccess(SmsSendingsActionTypes.SEND_TO_ARCHIVE, action.payload));
        yield put (addNotification({
            message: 'Рассылка отправлена в архив',
            variant: 'success',
        }))
    } catch (error) {
        yield put(smsApiResponseError(SmsSendingsActionTypes.SEND_TO_ARCHIVE, error));
        yield put (addNotification({
            message: 'Не удалось отправить рассылку в архив',
            variant: 'danger',
        }))
    }
}

function* updateStatus(action) {
    try {
        yield call(updateStatusApi, action.payload);
        yield put(smsApiResponseSuccess(SmsSendingsActionTypes.UPDATE_STATUS, action.payload));
    } catch (error) {
        yield put(smsApiResponseError(SmsSendingsActionTypes.UPDATE_STATUS, error));
    }
}

function* createSmsSending(action) {
    try {
        yield call(createSmsSendingApi, action.payload);
        yield put(smsApiResponseSuccess(SmsSendingsActionTypes.CREATE_SENDING));
        yield put (addNotification({
            message: 'Новая рассылка создана',
            variant: 'success',
        }))
    } catch (error) {
        yield put(smsApiResponseError(SmsSendingsActionTypes.CREATE_SENDING, error));
        yield put (addNotification({
            message: 'Не удалось создать рассылку',
            variant: 'danger',
        }))
    }
}

function* updateSmsSending(action) {
    try {
        yield call(updateSmsSendingApi, action.payload);
        yield put(smsApiResponseSuccess(SmsSendingsActionTypes.UPDATE_SENDING));
        yield put (addNotification({
            message: 'Рассылка изменена',
            variant: 'success',
        }))
    } catch (error) {
        yield put(smsApiResponseError(SmsSendingsActionTypes.UPDATE_SENDING, error));
        yield put (addNotification({
            message: 'Не удалось изменить рассылку',
            variant: 'danger',
        }))
    }
}

export function* watchGetSendings(): any {
    yield takeEvery(SmsSendingsActionTypes.GET_SENDINGS, getSendings);
}

export function* watchSendSendingToArchive(): any {
    yield takeEvery(SmsSendingsActionTypes.SEND_TO_ARCHIVE, sendSendingToArchive);
}

export function* watchGetAboutSending(): any {
    yield takeEvery(SmsSendingsActionTypes.GET_ABOUT_SENDING, getAboutSending);
}

export function* watchUpdateStatus(): any {
    yield takeEvery(SmsSendingsActionTypes.UPDATE_STATUS, updateStatus);
}

export function* watchCreateSmsSending(): any {
    yield takeEvery(SmsSendingsActionTypes.CREATE_SENDING, createSmsSending);
}

export function* watchUpdateSmsSending(): any {
    yield takeEvery(SmsSendingsActionTypes.UPDATE_SENDING, updateSmsSending);
}

function* smsSendingsSaga(): any {
    yield all(
        [fork(watchGetSendings), fork(watchUpdateStatus), fork(watchCreateSmsSending), fork(watchUpdateSmsSending), fork(watchGetAboutSending), fork(watchSendSendingToArchive)]
    );
}

export default smsSendingsSaga;
